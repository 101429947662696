<template>
  <div>
    <van-nav-bar
      title="注册成功"
      :left-arrow="false"
      fixed
      class="header_class"
      :border="false"
    >
    </van-nav-bar>
    <div class="logo">
      <img src="../assets//register/qrCode.jpg" alt="">
    </div>
    <div class="tips" v-if="isWeiXin">微信长按识别小程序打开/长按保存图片到本地使用微信扫一扫打开</div>
    <div class="tips" v-else>浏览器长按保存图片到本地使用微信扫一扫打开</div>
    <!-- <div class="next_btn">
      <button>
        <a href="">打开小程序</a>
      </button>
    </div> -->
  </div>
</template>
<style lang="scss" scoped>
.logo {
  width: 300px;
  height: 300px;
  // background-image: url("../assets/register/logo.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 35% auto 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #00ccff;
}

.tips {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 80%;
  margin: 20px auto;
  color: #ccc;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
}

.next_btn {
  width: 604px;
  height: 88px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 39px auto 71px;
  background-image: url("../assets/register/sub_bg.png");

  button {
    width: 100%;
    height: 100%;
    line-height: 88px;
    color: #00ccff;
    font-size: 30px;
    background-color: transparent;
    text-align: center;
    outline: none;
    border: none;

    a {
      color: #00ccff;
    }
  }
}
</style>

<script>
export default {
  data() {
    return {
      isWeiXin: false
    }
  },
  mounted(){
    this.is_weixn()
  },
  methods: {
    is_weixn() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.isWeiXin = true
      } else {
        this.isWeiXin = false
      }
    }
  }
}
</script>
